/**
 *
 * Disclaimer: Source code mentioned below is(are) Intellectual Property of
 * Crayon Data Holdings Limited (including its subsidiaries and affiliates).
 * Crayon Data Holdings Limited reserves right to own and control it the way
 * it may deem fit. You must refrain from use, access, read, modify, add or
 * delete, sell or use in any other package or programme pertaining to such
 * source code without explicit prior written approval of
 * Crayon Data Holding Limited. Breach of the same shall attract penalty as
 * applicable.
 *
 */

import uiConfig from "../uiConfig";

class config {
  constructor() {
    //this.domain = "https://bazaar-stage-api.crayondata.com/";
    this.isLoginEnabled = process.env.REACT_APP_LOGIN || "false";
    this.optimoleURL = "https://mljbz4pszxqr.i.optimole.com";
    this.domain =
      this.isLoginEnabled === "true"
        ? "https://hb-api.maya.ai/"
        : "https://hb-api.maya.ai/";
    this.authURL = "https://hb-auth.maya.ai/api/";
    this.apiVersion = "api/v3/";
    this.apiVersion_2 = "api/v3/";
    this.showBannerText = process.env.REACT_APP_BANNER_TEXT || true;
    this.siteId = 1;
    this.siteURL = "https://hb-trk.maya.ai/matomo.php";
    this.hasTasteMapScore = true;
    this.clientName = uiConfig.config.whitelabel.clientName.toUpperCase();
    this.apiKey = "auth";
    this.authApiKey = "h3b6uth9p1";
    this.isVideoPopupEnabled = false;
    this.storeLocationKey = true;
    this.mapKey = "AIzaSyBw3ZjNLWwNW61tP9CElJJHSP6uz7lYKms";
    this.visaOffers = true;
    this.hasTotalViews = true;
    this.notification = true;
  }
}

export default new config();
