import React, { PureComponent } from "react";
import Modal from "react-modal";

import hdfclogo from "../../assets/img/HDFC_logo.png";
import closeIcon from "../../assets/img/close_login.png";
import editIcon from "../../assets/img/edit_phone.png";
import hdfcLogoMbl from "../../assets/img/hdfc_logo_mbl.png";
import hdfcLogo from "../../assets/img/hdfc_logo_dp.svg";
import hdfcCloseMbl from "../../assets/img/hdfc_close_mbl.png";
// import hdfc_login_popup from "../../assets/img/hdfc_login_popup.png";
import Icons from "../Icons";

import {
  getCity,
  getError,
  Login,
  requestOTP,
  validateOTP,
  randomTokenGen
} from "../../service";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: 1000
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    height: "520px",
    padding: "0px",
    background: "#FFFFFF",
    borderRadius: "8px"
  }
};
const customStylesMbl = {
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: 1000,
    height: "100vh"
  },
  content: {
    width: "100%",
    height: "100%",
    padding: "0px",
    background: "#FFFFFF",
    borderRadius: "0px",
    inset: ""
  }
};
class LoginPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      phoneValidate: false,
      cardValidate: false,
      phonenumber: "",
      creditcardno: "",
      otp: "",
      phase1: true,
      phase2: false,
      errorText: "",
      errorMessage: "",
      resendDisabled: true,
      consentValue: false,
      timer: 30,
      activePhone: false,
      focusPhone: false,
      activeCard: false,
      activeOtp: false,
      focusOtp: false
    };
  }

  componentDidMount() {
    document.documentElement.classList.add("stop-scrolling");
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentDidUpdate() {
    if (this.props.openState === true) {
      this.updateScroll("add");
    } else {
      this.updateScroll("remove");
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  updateScroll = (action) => {
    if (action === "add") {
      document.documentElement.classList.add("stop-scrolling");
    } else if (action === "remove") {
      document.documentElement.classList.remove("stop-scrolling");
    }
  };
  changePhone = (e) => {
    let val = e.target.value;
    if (val.length >= 0 && val.length < 11 && val.match(/^[0-9]*$/g)) {
      this.setState({ phonenumber: e.target.value, errorMessage: "" });
      if (val.length === 10) {
        this.setState({ phoneValidate: true });
      } else {
        this.setState({ phoneValidate: false });
      }
    }
    if (val !== "") {
      this.setState({ activePhone: true });
    } else {
      this.setState({ activePhone: false });
    }
  };
  changeCard = (e) => {
    let val = e.target.value;
    if (val.length >= 0 && val.length < 5 && val.match(/^[0-9]*$/g)) {
      this.setState({ creditcardno: e.target.value, errorMessage: "" });
      if (val.length === 4) {
        this.setState({ cardValidate: true });
      } else {
        this.setState({ cardValidate: false });
      }
    }
    if (val !== "") {
      this.setState({ activeCard: true });
    } else {
      this.setState({ activeCard: false });
    }
  };
  changeOTP = (e) => {
    let val = e.target.value;
    this.setState({ errorText: "" });
    if (e.target.value.length < 7 && val.match(/^[0-9]*$/g)) {
      this.setState({ otp: e.target.value });
    }
    if (val !== "") {
      this.setState({ activeOtp: true });
    } else {
      this.setState({ activeOtp: false });
    }
  };

  requestOTP = async (e) => {
    let validP = this.state.phonenumber.length === 10;
    let validC = this.state.creditcardno.length === 4;
    if (validP && validC && this.state.consentValue) {
      var otp_gen_payload = {
        mobile_no: this.state.phonenumber,
        card_no: this.state.creditcardno
      };

      requestOTP(otp_gen_payload)
        .then((res) => {
          if (res.message === "Success") {
            if (this.timer) {
              clearInterval(this.timer);
            }
            this.setState(
              {
                phase1: false,
                phase2: true,
                timer: 30,
                errorText: ""
              },
              () => {
                this.timer = setInterval(() => {
                  this.setState({ timer: this.state.timer - 1 });
                }, 1000);
              }
            );
          } else {
            this.setState({
              phase1: true,
              phase2: false,
              errorMessage: res.message
            });
          }
        })
        .catch((err) => {
          this.setState({
            phase1: true,
            phase2: false,
            errorMessage: getError(err)
          });
          //alert(getError(err));
        });
    }
  };

  sendApi = (e) => {
    this.setState({ phase1: true, phase2: false });
  };
  editPhone = () => {
    this.setState({
      phase1: true,
      phase2: false,
      otp: "",
      timer: 30,
      activeOtp: false
    });
  };

  resendOTP = () => {
    if (this.state.timer < 0) {
      this.setState({ otp: "" });
      this.requestOTP();
    }
  };

  closeForm = () => {
    this.setState({
      phoneValidate: false,
      cardValidate: false,
      phonenumber: "",
      creditcardno: "",
      otp: "",
      phase1: true,
      phase2: false
    });

    this.updateScroll("remove");
    this.props.closeLogin("normal");
  };

  _closeLogin = () => {
    if (
      localStorage.getItem("interactionFrom") &&
      localStorage.getItem("interactionFrom") === "Merchant"
    ) {
      this.props.closeLogin("merchant");
    } else if (
      localStorage.getItem("interactionFrom") &&
      localStorage.getItem("interactionFrom") === "Wishlist"
    ) {
      this.props.closeLogin("Wishlist");
    } else {
      this.props.closeLogin("submit");
    }
  };

  submitApi = (e) => {
    let validP = this.state.phonenumber.length === 10;
    let validC = this.state.creditcardno.length === 4;
    let validOTP = this.state.otp.length === 6;
    if (validP && validC && validOTP && this.state.consentValue) {
      var otp_verify_payload = {
        mobile_no: this.state.phonenumber,
        card_no: this.state.creditcardno,
        otp: this.state.otp
      };

      validateOTP(otp_verify_payload).then(async (response) => {
        let res = await response.json();
        if (response.status === 200) {
          localStorage.setItem("isLoggedin", "true");
          window.sessionStorage.setItem("USERID", res.id);
          localStorage.setItem("TOKEN", res.id);
          localStorage.setItem("USERID", res.id);
          localStorage.setItem("notification-consent", "default");
          this.setState({
            phoneValidate: false,
            cardValidate: false,
            phonenumber: "",
            creditcardno: "",
            otp: "",
            phase1: true,
            phase2: false
          });
          this.updateScroll("remove");
          this._closeLogin();
          window.location.reload();
        } else {
          this.setState({ errorText: res.message });
        }
      });
    }
  };

  closeLoginButton = () => {
    this.setState({
      phoneValidate: false,
      cardValidate: false,
      phonenumber: "",
      creditcardno: "",
      otp: "",
      phase1: true,
      phase2: false
    });

    this.updateScroll("remove");
    this.props.closeLogin("normal");
  }

  guestLogin = (e) => {
    let tok = randomTokenGen(16);
    localStorage.setItem('TOKEN',tok)
    // localStorage.setItem('USERID',tok)
    // window.sessionStorage.setItem('USERID',tok);
    this.setState({
      phoneValidate: false,
      cardValidate: false,
      phonenumber: "",
      creditcardno: "",
      otp: "",
      phase1: true,
      phase2: false
    });

    this.updateScroll("remove");
    this.props.closeLogin("normal");
  };

  handleCheckbox = (e) => {
    e.target.setCustomValidity("");
    if (e.target.id === "consentCheck" && e.target.checked) {
      this.setState({ consentValue: true });
    } else {
      this.setState({ consentValue: false });
    }
  };

  handleFocus = (val) => {
    let stateValue = val;
    this.setState({ [stateValue]: true });
  };

  handleBlur = (val) => {
    let stateValue = val;
    this.setState({ [stateValue]: false });
  };

  render() {
    const { activePhone, focusPhone, activeCard, activeOtp, focusOtp } =
      this.state;
    return (
      <div>
        {this.state.width >= 768 && (
          <Modal
            isOpen={this.props.openState}
            //onAfterOpen={afterOpenModal}
            onRequestClose={this.closeForm}
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            contentLabel="Login Modal"
          >
            <div className="lmodal">
              <div className="lmodal-logo">
                <img
                  alt="hdfcLogo"
                  className="mod-logo"
                  src={hdfcLogoMbl}
                  width="141px"
                  // height="27px"
                ></img>
                {/* <span>Personalisation engine understanding your tastes</span> */}
                <div className="mdl-fot">
                  {" "}
                  <span
                    className="maya-logo cursor"
                    onClick={() => {
                      window.open("https://maya.ai/");
                    }}
                  >
                    <Icons name="mayatexthdfc" width={197} height={""} />
                  </span>
                </div>
              </div>
              <div className="lmodal-content">
                <div className="close-btn" onClick={this.closeLoginButton}>
                  <img src={closeIcon} width="32px" height="32px" />
                </div>
                <div className="title">
                  <h3>Login</h3>
                  <p>Enjoy exclusive offers across 1000+ brands around you </p>
                </div>
                <form
                  autocomplete="off"
                  onSubmit={(e) => e.preventDefault()}
                  onInvalid={(e) => {
                    e.target.id === "consentCheck" && !this.state.consentValue
                      ? e.target.setCustomValidity("Agree to Proceed")
                      : e.target.setCustomValidity("");
                  }}
                >
                  <div className="form-phone">
                    <label for="phonenumber" className="phone-label">{`${
                      this.state.phase1 ? "Enter your" : "Your"
                    } registered mobile number`}</label>
                    <label className="phone-prefix">+91 - </label>
                    {this.state.phase2 ? (
                      <input
                        type="text"
                        readonly="readonly"
                        onChange={this.changePhone}
                        minLength={10}
                        maxLength={10}
                        pattern="[0-9]{10}"
                        className={`form-control ${
                          this.state.phase2 ? "form-control-edit" : ""
                        }`}
                        value={this.state.phonenumber}
                        id="phonenumber"
                      />
                    ) : (
                      <input
                        type="text"
                        required
                        onChange={this.changePhone}
                        minLength={10}
                        maxLength={10}
                        pattern="[0-9]{10}"
                        className={`form-control ${
                          this.state.phase2 ? "form-control-edit" : ""
                        }`}
                        value={this.state.phonenumber}
                        id="phonenumber"
                      />
                    )}
                    {this.state.phase2 && (
                      <div
                        onClick={this.editPhone}
                        id="editPhone"
                        className="edit-mbl"
                      >
                        <img src={editIcon} />
                      </div>
                    )}
                  </div>
                  {this.state.phase1 && (
                    <div className="form-credit-card">
                      <label for="cardnumber" className="card-label">
                        Enter the last 4 digits of your credit card
                      </label>
                      <input
                        type="password"
                        required
                        onChange={this.changeCard}
                        minLength={4}
                        maxLength={4}
                        className="form-control"
                        value={this.state.creditcardno}
                        id="cardnumber"
                      />
                    </div>
                  )}
                  {this.state.phase1 && this.state.errorMessage.length > 0 && (
                    <div className="error-text">{this.state.errorMessage}</div>
                  )}
                  {this.state.phase1 && (
                    <div className="form-consent">
                      <input
                        type="checkbox"
                        id={"consentCheck"}
                        required
                        onClick={this.handleCheckbox}
                        checked={this.state.consentValue}
                      />
                      <span>
                        {
                          "I agree to share my registered mobile number and last 4 digits of my HDFC bank credit card for the purpose of authentication. I understand that these details will not be used for any other purpose except for authentication."
                        }
                      </span>
                    </div>
                  )}
                  {this.state.phase2 && (
                    <div className="form-otp">
                      <label for="otp" className="otp-label">
                        Enter OTP
                      </label>
                      <input
                        type="text"
                        required
                        onChange={this.changeOTP}
                        minLength={6}
                        maxLength={6}
                        className="form-control"
                        value={this.state.otp}
                        id="otp"
                      />
                    </div>
                  )}
                  {this.state.phase2 && this.state.errorText.length > 0 && (
                    <div className="error-text">{this.state.errorText}</div>
                  )}

                  {this.state.phase1 && (
                    <button onClick={this.requestOTP} className="btn mt-14">
                      Request OTP
                    </button>
                  )}

                  {this.state.phase2 && (
                    <div className="submit-otp">
                      <button
                        onClick={this.submitApi}
                        id="continue"
                        className="btn mt-14"
                      >
                        Continue
                      </button>
                      <div className="timer-div">
                        {this.state.timer > 0 && (
                          <div className="timer mt-14 mr-2">
                            {this.state.timer + "s"}
                          </div>
                        )}
                        <div
                          onClick={this.resendOTP}
                          id="resend"
                          className={` mt-14 mr-2 ml-8 ${
                            this.state.timer > 0 ? "resend-disabled" : "resend"
                          }`}
                        >
                          Resend
                        </div>
                      </div>
                    </div>
                  )}
                </form>
                <div className="foot-text">
                  <p>
                    Don't have an HDFC Bank Credit Card? No worries! Check out
                    all our offers by logging in as{" "}
                    <div className="guest" onClick={this.guestLogin}>
                      GUEST
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {this.state.width < 768 && (
          <Modal
            isOpen={this.props.openState}
            //onAfterOpen={afterOpenModal}
            onRequestClose={this.closeForm}
            shouldCloseOnOverlayClick={false}
            style={customStylesMbl}
            contentLabel="Login Modal"
          >
            <div className="lmodal-mbl">
            <div className="lmodal-mbl-logo">
                <img className="mod-logo" src={hdfcLogoMbl} width="auto"></img>
                {/* <div className="logo-text">
                  Personalization engine understanding human tastes
                </div> */}
                <div className="close-btn" onClick={this.closeLoginButton}>
                  <img src={hdfcCloseMbl} width={14} height={14} />
                </div>
              </div>
              <div className="lmodal-mbl-content">
                {/* <div className="close-btn">
                            <img src={closeIcon} width="32px" height="32px"/>
                        </div> */}
                <div className="title">
                  <span className="login-name">Login</span>
                  <p>
                    Enjoy exclusive discounts and offers across 10000+ brands
                    and stores around you{" "}
                  </p>
                </div>
                <form autocomplete="off" onSubmit={(e) => e.preventDefault()}>
                  <div className="form-phone" id="float-label">
                    <input
                      type="text"
                      required
                      readonly={this.state.phase2 ? true : false}
                      onChange={this.changePhone}
                      minLength={10}
                      maxLength={10}
                      pattern="[0-9]{10}"
                      className={`form-control ${
                        this.state.phase2 ? "form-control-edit" : "normal"
                      }`}
                      value={this.state.phonenumber}
                      id="phonenumber"
                      onFocus={() => this.handleFocus("focusPhone")}
                      onBlur={() => this.handleBlur("focusPhone")}
                    />
                    {this.state.phase2 && (
                      <div
                        onClick={this.editPhone}
                        id="editPhone"
                        className="edit-mbl"
                      >
                        <img src={editIcon} />
                      </div>
                    )}
                    <label
                      for="phonenumber"
                      className={
                        activePhone === true
                          ? "phone-label-active"
                          : "phone-label"
                      }
                    >
                      Your registered mobile number
                    </label>
                    {(focusPhone === true || activePhone === true) && (
                      <div className="phone-prefix">+91 - </div>
                    )}
                  </div>
                  {this.state.phase1 && (
                    <div className="form-credit-card" id="float-label-card">
                      <input
                        type="password"
                        required
                        onChange={this.changeCard}
                        minLength={4}
                        maxLength={4}
                        className="form-control"
                        value={this.state.creditcardno}
                        id="cardnumber"
                      />
                      <label
                        for="cardnumber"
                        className={
                          activeCard === true
                            ? "card-label-active"
                            : "card-label"
                        }
                      >
                        Enter the last 4 digits of your credit card
                      </label>
                    </div>
                  )}
                  {this.state.phase1 && this.state.errorMessage.length > 0 && (
                    <div className="error-text">{this.state.errorMessage}</div>
                  )}
                  {this.state.phase1 && (
                    <div className="form-consent">
                      <input
                        type="checkbox"
                        id={"consentCheck"}
                        onClick={this.handleCheckbox}
                        required
                        checked={this.state.consentValue}
                      />
                      <span>
                        {
                          "I agree to share my registered mobile number and last 4 digits of my HDFC bank credit card for the purpose of authentication. I understand that these details will not be used for any other purpose except for authentication."
                        }
                      </span>
                    </div>
                  )}
                  {this.state.phase2 && (
                    <div className="form-otp" id="float-label-otp">
                      <input
                        type="text"
                        required
                        onChange={this.changeOTP}
                        minLength={6}
                        maxLength={6}
                        className="form-control"
                        value={this.state.otp}
                        id="otp"
                        onFocus={() => this.handleFocus("focusOtp")}
                        onBlur={() => this.handleBlur("focusOtp")}
                      />
                      <label
                        for="otp"
                        className={
                          activeOtp === true ? "otp-label-active" : "otp-label"
                        }
                      >
                        Enter OTP
                      </label>
                      {(focusOtp === true || activeOtp === true) && (
                        <div className="timer-div">
                          {this.state.timer > 0 && (
                            <div className="timer mr-2">
                              {this.state.timer + "s"}
                            </div>
                          )}
                          <div
                            onClick={this.resendOTP}
                            id="resend"
                            className={`mr-2 ml-8 ${
                              this.state.timer > 0
                                ? "resend-mbl-disabled"
                                : "resend-mbl"
                            }`}
                          >
                            Resend
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.phase2 && this.state.errorText.length > 0 && (
                    <div className="error-text">{this.state.errorText}</div>
                  )}

                  {this.state.phase1 && (
                    <button onClick={this.requestOTP} className="btn mt-14">
                      Request OTP
                    </button>
                  )}

                  {this.state.phase2 && (
                    <div className="submit-otp-mbl">
                      <button
                        onClick={this.submitApi}
                        id="continue"
                        className="btn mt-14"
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </form>
                <div className="foot-text">
                  <p>
                    Don't have an HDFC Bank Credit Card? No worries! Check out
                    all our offers by logging in as{" "}
                    <div className="guest" onClick={this.guestLogin}>
                      GUEST
                    </div>
                  </p>
                </div>
              </div>
              <div className="foot-logo">
                <div className="mdl-fott">
                  {" "}
                  <span
                    className="maya-logo cursor"
                    // onClick={() => {
                    //   window.open("https://maya.ai/");
                    // }}
                  >
                    <Icons name="mayatexthdfc" width={197} height={""} />
                  </span>
                </div>
                <div className="cpy-text">
                  © 2023 HDFC Smartbuy, Inc. All rights reserved Privacy · Terms
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default LoginPopup;
