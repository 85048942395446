// Cookie Component

import React , { useState, useEffect } from "react";
import cancel_icon from "../../assets/img/close-icon.png";
import "./index.scss";
import Modal from 'react-modal';

//import { useNavigate } from "react-router-dom";

const customStyles = {
  overlay:{
      background: "rgba(0, 0, 0, 0.4)",
      zIndex: 1100
  },
  content: {
    padding: "0px",
    inset : 'auto',
    border : 'none'
  },

};

const CookiePopupComponent = (props) => {
  //const navigate = useNavigate();
  const [popup, setPopup] = useState(true);
  const language = localStorage.getItem("language");
  const [width, setWidth] = useState(1200);

  var handleMenu = (e) => {
    // props.setActiveItem('privacy');
    // sessionStorage.setItem("currPage", 'privacy');
    // props.history.push("/home/profile", 'privacy')
    window.open('https://www.hdfcbank.com/personal/useful-links/privacy#/Cookie-Policy', '_blank');
  };

  var handleClick = () => {
    localStorage.setItem('cookies_enabled', 'true')
    setPopup(false)
  }

useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

const updateMedia = () => {
    setWidth(window.innerWidth);
  };

  return (
   popup &&  
   <Modal
    isOpen={popup}
    //onAfterOpen={afterOpenModal}
    onRequestClose={() => setPopup(false)}
    shouldCloseOnOverlayClick={false}
    style={customStyles}
    contentLabel="Cookie Modal">
    <div className="cookie-popup-container animate fadeInUp" >
    <div className="close-icon" onClick={() => handleClick()}>
        <img src={cancel_icon} />
      </div>
        <div className="cookie-content" style={{textAlign : language == 'AR' ? 'right' : 'left' }}>
            <div className="cookie-title">
               {language == 'AR' ? 'ملفات تعريف الارتباط والخصوصية' : 'Cookies & Privacy'} 
            </div>
          
            <div className="cookie-desc" >
                {language == 'AR' ? <div>تحترم منصة smartdeals خصوصيتك بكونها لا تتتبع أو تخزن معلوماتك من خلال ملفات تعريف الارتباط، إذ يتم استخدام التخزين المحلي وتخزين الجلسة فقط لتخزين بيانات محدودة وتزويدك بأفضل تجربة. تعرف على المزيد حول سياسة الخصوصية الخاصة بنا <span className="learn-more"  onClick={handleMenu} id={"privacypolicy"}>هنا.
              </span></div> : <div>Smartbuy respects your privacy. We do not track or store your information using cookies. We use only local and session storage to store limited data and provide you with the best browsing experience. <span className="learn-more"  onClick={handleMenu} id={"privacypolicy"}>Learn more about our privacy policy here.</span></div>}
            </div>
        </div>
    </div>
    </Modal>
  );
};

export default CookiePopupComponent;